import React, { useState } from "react";
import Illustration from "../../../../../assets/img/risk-mgt.svg";
import { RiskManagementStyled } from "./about-styles";

const RiskManagement = () => {
    const [showMore, setShowMore] = useState(false)
    const intro = "We are committed to maintaining the highest standards of risk management to ensure the safety, stability, and success of our business operations. We approach risk from an enterprise-wide standpoint, giving us the leverage to manage Kuda Microfinance Bank Limited’s (the “Bank”) multi-risks which include but are not limited to market risk, credit risk, operational risk, material risk, liquidity risk, regulatory compliance risk, cybersecurity risk, reputational risk and residual risk. Our risk management framework is designed to prevent, identify, assess, monitor, and mitigate risks across all areas of our operations enabling us to manage all risks that can hinder the Bank from achieving its strategic objectives."

    return (
        <RiskManagementStyled>
            <div className="bg-offwhite kuda-ignore--container">
                <div className="kuda-section kuda-standard--section kuda-story--section mt-0  py-4 py-md-5">
                    <div className="kuda-section--inner">
                        <div className="kuda-section--100">
                            <h2 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                                Our Risk Management Practices
                            </h2>
                            <div className="justify-center flex flex-column align-center kuda-story--wrap px-3">
                                <div className="p-text text-center ">

                                    <div className="kuda-section--max color-black flex text-center flex-md-row flex-column">
                                        <div className="desktop-only">
                                            <img alt="kuda Microfinance Bank"
                                                src={Illustration}
                                                className="mr-md-2"
                                            />
                                            {showMore ? intro : intro.slice(0, 707).concat('...')}
                                            {!showMore && <span>
                                                <a onClick={() => setShowMore(!showMore)} rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">Read  more</a>
                                            </span>}

                                        </div>
                                        <div className="flex flex-column mobile-only justify-center">
                                            <img alt="kuda Microfinance Bank"
                                                src={Illustration}
                                                className="mx-auto"
                                            />
                                            <div className="py-3">
                                            {showMore ? intro : intro.slice(0, 707).concat('...')}
                                            {!showMore && <span>
                                                <a onClick={() => setShowMore(!showMore)} rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">Read  more</a>
                                            </span>}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                {showMore &&
                                    <div className="p-text text-center color-black kuda-section--max bottom--spacing">
                                        <div className="mb-3 mb-md-4">
                                            We have several risk management policies and procedures which apply to our employees, contractors, consultants and any third party in a business relationship with the Bank.
                                        </div>
                                        <div className="mb-3 mb-md-4">
                                            Our risk management policies provide a comprehensive framework for preventing, identifying, assessing, and mitigating risks that potentially impact business continuity. These policies ensure that the Bank’s overall risk exposure remains within prudent limits, aligned with its capital availability and risk appetite.
                                        </div>
                                        <div className="mb-3 mb-md-4">
                                            Kuda Microfinance Bank Limited is committed to managing risks by proactively preventing, identifying and assessing risks across all aspects of our business, including credit, market, operational, compliance, and reputational risks. This approach ensures that potential challenges are recognised and addressed promptly.
                                        </div>
                                        <div className="mb-3 mb-md-4">
                                            Our risk mitigation strategies are built on strong internal controls, sound ethics and governance practices, and a culture of accountability. Through the use of advanced analytical tools and risk management techniques the Bank is committed to minimising the impact of identified risks on our operations.
                                            All stakeholders in a business relationship with Kuda Microfinance Bank Limited will comply with our risk management policies and ensure that any observed risk is promptly escalated and properly managed.
                                        </div>

                                        <div className="">
                                            The Bank demonstrates compliance with international risk management standards/practices through its certifications in ISO 27001 (Information Security) and ensures strict adherence to regulatory requirements, including the Nigeria Data Protection Regulation, 2019 and the Nigeria Data Protection Act, 2023.
                                        </div>



                                    </div>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </RiskManagementStyled>
    )
}

export default RiskManagement;
