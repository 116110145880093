import React, { useEffect, useState } from 'react';
import { ImageSliderStyles } from './about-styles';

import { useRef } from 'react';
import Slider from "react-slick";

const SectionSlider = ({ images }) => {
    const settings = {
        className: "kuda-ignore--container flex flex-row multi-slider-container",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
    };
    function NextArrow(props) {
        const {  onClick } = props;
        return (
          <div
            className="next-btn"
            onClick={onClick}
          >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z" fill="#C7C7CC" />
                    </svg>
          </div>
        );
      }
      
      function PrevArrow(props) {
        const { onClick } = props;
        return (
            <div
            className="prev-btn"
            onClick={onClick}
          >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z" fill="#C7C7CC" />
                    </svg>
          </div>
        );
      }
    return (
        <ImageSliderStyles>
            <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="slide">
                            {image}
                        </div>
                    ))}
                </Slider>
        
        </ImageSliderStyles>
    );
};

export default SectionSlider;