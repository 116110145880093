import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import MissionIllustration from "../../../../assets/img/mission-illustration.svg"
import { scrollToElement } from "../../../utility/utils";
import Testimonials from "../home/sections/testimonials";
import ImageTextLeft from "../general/imageTextLeft";
import BoardOfDirectors from "./sections/boardOfDirectors";
import Milestone from "./sections/milestones";
import RiskManagement from "./sections/riskManagement";
import CenteredTextSection from "../scam/components/centeredTextSection";
import Initiatives from "./sections/initiatives";
import AboutUsHeader from "./sections/about-us-header";
const entryContent = {
  title: "Kuda Microfinance Bank Limited",
  subtitle: <div className="flex flex-column">
    <span className="mb-3">Kuda Microfinance Bank Limited (RC 796975) is a private limited liability company incorporated under the laws of the Federal Republic of Nigeria and licensed by the Central Bank of Nigeria as a microfinance bank. </span>
    <span>We operate as a microfinance institution leveraging technology to provide banking and related financial services to our retail and business customers.</span>
  </div>

}
const kudaMission = {
  title: "Our mission",
  subtitle: <span className="mb-sm-5 mb-lg-0">To make financial services accessible, affordable, and rewarding for every Nigerian.</span>,
  illustration: <img src={MissionIllustration} />,
}
const milestones = [
  {
    year: 2020,
    events: [
      {
        date: 'May 2020',
        description: 'In May 2020, we launched a COVID-19 fund to provide relief to vulnerable groups in Lagos.',
      },
    ],
    color: '#48D38A',
    colorContrast: '#DCFFEC',
  },
  {
    year: 2021,
    events: [
      {
        date: 'March 2021',
        description: 'In March 2021, we piloted overdrafts.',
      },
      {
        date: 'November 2021',
        description: 'In November 2021, we launched Visa cards.',
      },
      {
        date: 'December 2021',
        description: 'In December 2021, we passed the two-million-customer milestone.',
      },
      {
        date: 'February 2021',
        description: 'In February 2021, we passed 1.5 million iOS downloads.',
      },
    ],
    color: '#40196D',
    colorContrast: '#EFF1FF',
  },
  {
    year: 2022,
    events: [
      {
        date: 'March 2022',
        description: 'In March 2022, we passed the three-million-customer milestone.',
      },
      {
        date: 'July 2022',
        description: 'In July 2022, we passed the four-million-customer milestone.',
      },
      {
        date: 'October 2022',
        description: 'In October 2022, we launched the Kuda Business account.',
      },
    ],
    color: '#FFC83E',
    colorContrast: '#FFF5DB',
  },
  {
    year: 2023,
    events: [
      {
        date: 'June 2023',
        description: 'In June 2023, we passed the six-million-customer milestone.',
      },
    ],
    color: '#1DCBEF',
    colorContrast: '#DBF8FF',
  },
];

const policyIntro = {
  heading: "Our Communications Policy",
  subHeading: (
    <div className="flex flex-column text-center leading-md-6 f-md-16">
      <div>Kuda Microfinance Bank Limited (“Kuda MFB” or “Bank”) recognises the importance of effective communication as a cornerstone of good management. This Policy (Communication Policy) aims to ensure that all stakeholders receive timely and relevant information, fostering a relationship that is mutually beneficial. </div>

    </div>
  )
}

const communicationPolicyData = [
  {
    sectionTitle: "Objectives of this Policy",
    description: "This Policy will ensure that Kuda MFB:",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2"> Remains visible, accessible, and accountable to its stakeholders.</li>
        <li className="mb-2">Provides prompt, courteous, and responsive service tailored to customer needs.</li>
        <li className="mb-2">Promotes the Bank's interests and enhances its brand image.</li>
        <li className="mb-2">Clearly defines communication channels in line with the organisational structure.</li>
        <li className="mb-2">Establishes consequences for the breach of this Policy.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Dissemination of this Policy",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">The Communications Unit will disseminate this Policy.</li>
        <li className="mb-2">The Human Resources & People Department is responsible for ensuring that all employees receive this Policy through internal communication channels.</li>
        <li className="mb-2">Compliance with this Policy will be monitored by the Internal Audit Department.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Language Guidelines",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">All official communications must be in formal English.</li>
        <li className="mb-2">Written materials (policies, reports, etc.) should be clear, objective, and easily understandable.</li>
        <li className="mb-2">Correspondence with the Bank must also be in English, except where local languages are more appropriate for target audiences.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Branding and Logo Usage",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">The Kuda MFB logo must appear on all official documents and marketing materials.</li>
        <li className="mb-2">The logo must not be altered and should adhere to the brand guidelines at all times.</li>
        <li className="mb-2">Consistency in branding across all platforms is essential to maintain a strong corporate identity.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Internal Communication",
    description: "Internal communication is vital for fostering a collaborative environment. Key points include:",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">All staff communications should be routed through appropriate channels to avoid misinformation.</li>
        <li className="mb-2">Only designated personnel may send mass emails or internal memos.</li>
        <li className="mb-2">Confidential information must not be shared outside the organisation without proper authorisation.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "External Communications",
    description: "External communications must be handled with care to maintain a positive public image. This includes:",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">All external correspondence must use official letterhead and approved fonts.</li>
        <li className="mb-2">Complaints from external parties should be directed to the Customer Experience Department for resolution.</li>
        <li className="mb-2">Media relations are managed by the Brand Unit, staff must not engage with media without prior approval.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Media Relations",
    description: "Kuda MFB values its relationship with the media. The following guidelines apply:",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">The Managing Director & Chief Executive Officer serves as the primary spokesperson for the Bank.</li>
        <li className="mb-2">Only authorized personnel may speak on behalf of the Bank or respond to media inquiries.</li>
        <li className="mb-2">Regular press releases and media engagements should be planned to maintain visibility.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Government Relations",
    description: "The Bank will maintain a healthy relationship with government entities while adhering to regulatory standards. Key points include:",
    points: [
      "",
      "",
    ],
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">Respect for laws governing operations within the industry is paramount.</li>
        <li className="mb-2">Engagements with government officials should focus on building relationships rather than political affiliations.</li>
      </ul>
    </div>
  },
  {
    sectionTitle: "Corporate Social Responsibility (CSR)",
    description: "Kuda MFB is committed to responsible community engagement. This includes:",
    data: <ul className="diclaimer-list leading-md-9 f-md-16">
      <li className="mb-2">Supporting initiatives that benefit local communities and address societal challenges.</li>
      <li className="mb-2">Promoting environmental sustainability through relevant programs and partnerships.</li>
    </ul>
  },
  {
    sectionTitle: "Crisis Communications",
    data: <ul className="diclaimer-list leading-md-9 f-md-16">
      <li className="mb-3"><span className="text-bold">Crisis Management Team (CMT):</span> A Crisis Management Team will be formed comprising key personnel from various departments/units including Communications, HR & People, Customer Experience, Legal, and Compliance.</li>
      <li className="mb-3"><span className="tex-bold">Crisis Communication Plan:</span> A detailed plan will outline procedures for responding to crises that may impact public perception or operational integrity.</li>
      <li className="mb-3">
        <div className="flex flex-column">
          <div className="text-bold">Immediate Response Protocols:</div>
          <div>Upon identifying a crisis:</div>
          <div className="text-left pl-2 flex flex-column  leading-md-6 f-md-16">
            <div>1. The CMT will convene immediately to assess the situation.</div>
            <div>2. A spokesperson will be appointed to communicate with stakeholders.</div>
            <div>3. Initial statements will be prepared within one hour of crisis identification.</div>
          </div>
        </div>
      </li>
      <li className="mb-3"><span className="text-bold">Stakeholder Communication:</span> Regular updates will be provided throughout the crisis duration through appropriate channels (press releases and social media updates).</li>
      <li className="mb-3"><span className="text-bold">Post-Crisis Evaluation:</span> After resolving a crisis, a debriefing session will be conducted to evaluate response effectiveness and identify areas for improvement.</li>

    </ul>
  },
  {
    sectionTitle: "Sanctions",
    data: <ul className="diclaimer-list leading-md-9 f-md-16">
      <li className="mb-2"><span className="text-bold">Breach of this Policy:</span> Any employee found violating this Policy will face disciplinary action as laid out in the Kuda MFB Professional Conduct Code and Kuda MFB Disciplinary Policy and Sanctions Grid.</li>
      <li className="mb-2"><span className="text-bold">Reporting Violations:</span> Employees are encouraged to report any breach through established internal reporting channels without fear of retaliation.</li>
      <li className="mb-2"><span className="text-bold">Investigation Procedures: </span>All reported breaches will be investigated promptly by the HR & People Department in collaboration with relevant departments to determine appropriate actions based on findings.</li>
    </ul>
  },
  {
    sectionTitle: "Conclusion",
    data: <div className="flex flex-column">
      <ul className="diclaimer-list leading-md-9 f-md-16">
        <li className="mb-2">This Policy serves as a framework for effective communication at Kuda MFB. Adherence to the guidelines in this Policy will enhance stakeholder relationships, promote transparency, and uphold the Bank's reputation in the financial sector. Regular reviews of this Policy will ensure it remains relevant and effective in achieving its objectives.</li>
      </ul>
      <div className=" leading-md-9 f-md-16 text-left mb-4">Contact Information:</div>
      <div className=" leading-md-9 f-md-16 text-left mb-4">Email - help@kuda.com </div>
      <div className=" leading-md-9 f-md-16 text-left mb-4">Phone Number -  0700022555832 (available from 8:00 am to 5:00 pm on weekdays)</div>
    </div>
  },
];
const NgAboutUs = () => {

  useEffect(() => {
    scrollToElement();
    window.addEventListener("scroll", function () {
      scrollToElement();
    });
  }, [])

  return (
    <Fragment>
      <AboutUsHeader title={entryContent.title} subtitle={entryContent.subtitle} ctaClass="flex mx-auto justify-center" />
      <ImageTextLeft
        title={kudaMission.title}
        subtitle={kudaMission.subtitle}
        illustration={kudaMission.illustration}
        className={"mt-2"}

      />
      <Milestone milestones={milestones} />
      <RiskManagement />
      <BoardOfDirectors />

      <Initiatives />


      <CenteredTextSection
        heading={policyIntro.heading}
        subHeading={policyIntro.subHeading}
        className="kuda-section  mt-0 pt-4 pt-md-5">
        <div>

          {communicationPolicyData.map((item, i) => {
            return <div className={`flex flex-column ${i === 0 ? "pb-md-4 pb-3" : "py-md-4 py-3"}`} key={i}>
              <div className="flex flex-column">
                <div className="color-primary text-bold text-md-s text-center">{item?.sectionTitle}</div>
                <div className="pt-2 flex flex-column color-black " style={{ lineHeight: '1.625rem' }}>
                  {item?.description && <div className="kuda-section--100">{item?.description}</div>}
                  {item?.data && item?.data}

                </div>
              </div>
            </div>
          })}
        </div>
      </CenteredTextSection>
      <Testimonials hidePublications/>
      <CTA />
    </Fragment>
  )
}

export default NgAboutUs;
