import React from "react"
import EntryBadges from "../../general/badges";

const AboutUsHeader = (props) => {

    return (
        <div className="kuda-ignore--container career-noMarginTop">
        <div className="kuda-section--inner business-centered-header">
            <div className="text-center justify-center flex flex-column">

                <div className="business-header-container flex flex-column justify-center">

                    <h1 className="mx-auto business-header-title color-primary">
                        {props?.title}
                    </h1>
                    <p className={`mx-auto business-header-subtext color-black  title-bottom--spacing
                        ${props.isDownload ? "desktop-only" : ""}`}>
                        {props?.subtitle}
                    </p>

                    <div className="flex justify-center mx-auto">
                        <EntryBadges
                            className={props.ctaClass}
                            isBusiness={props.isBusiness}
                            isAmbassador={props.isAmbassador}
                            ambassadorUrl={props.ambassadorUrl}
                            hideDownload={props.hideDownload}
                        />

                    </div>
                </div>

            </div>

        </div>
    </div>
    )
}

export default AboutUsHeader;
