import React from "react";
import CenteredTextSection from "../../scam/components/centeredTextSection";
import SectionSlider from "./section-slider";

import CovidImage1 from "../../../../../assets/img/covid-19/image1.svg"
import CovidImage2 from "../../../../../assets/img/covid-19/image2.svg"
import CovidImage3 from "../../../../../assets/img/covid-19/image3.svg"
import CovidImage4 from "../../../../../assets/img/covid-19/image4.svg"
import CovidImage5 from "../../../../../assets/img/covid-19/image5.svg"
import CovidImage6 from "../../../../../assets/img/covid-19/image6.svg"
import CovidImage7 from "../../../../../assets/img/covid-19/image7.svg"
import CovidImage8 from "../../../../../assets/img/covid-19/image8.svg"
import CovidImage9 from "../../../../../assets/img/covid-19/image9.svg"
import CovidImage10 from "../../../../../assets/img/covid-19/image10.svg"
import CovidImage11 from "../../../../../assets/img/covid-19/image11.svg"
import CovidImage12 from "../../../../../assets/img/covid-19/image12.svg"
import CovidImage13 from "../../../../../assets/img/covid-19/image13.svg"

import WWD1 from "../../../../../assets/img/wwd/image1.svg"
import WWD2 from "../../../../../assets/img/wwd/image2.svg"
import WWD3 from "../../../../../assets/img/wwd/image3.svg"
import WWD4 from "../../../../../assets/img/wwd/image4.svg"
import WWD5 from "../../../../../assets/img/wwd/image5.svg"
import WWD6 from "../../../../../assets/img/wwd/image6.svg"
import WWD7 from "../../../../../assets/img/wwd/image7.svg"
import WWD8 from "../../../../../assets/img/wwd/image8.svg"
import WWD9 from "../../../../../assets/img/wwd/image9.svg"
import WWD10 from "../../../../../assets/img/wwd/image10.svg"
import WWD11 from "../../../../../assets/img/wwd/image11.svg"
import WWD12 from "../../../../../assets/img/wwd/image12.svg"


const Initiatives = () => {

    const covidImages = [
        <img src={CovidImage1} className="h-100" />,
        <img src={CovidImage2} />,
        <img src={CovidImage3} />,
        <img src={CovidImage4} />,
        <img src={CovidImage5} />,
        <img src={CovidImage6} />,
        <img src={CovidImage7} />,
        <img src={CovidImage8} />,
        <img src={CovidImage9} />,
        <img src={CovidImage10} />,
        <img src={CovidImage11} />,
        <img src={CovidImage12} />,
        <img src={CovidImage13} />,
    ];
    const wwdImages = [
        <img src={WWD1} />,
        <img src={WWD2} />,
        <img src={WWD3} />,
        <img src={WWD4} />,
        <img src={WWD5} />,
        <img src={WWD6} />,
        <img src={WWD7} />,
        <img src={WWD8} />,
        <img src={WWD9} />,
        <img src={WWD10} />,
        <img src={WWD11} />,
        <img src={WWD12} />,
    ];

    return (
        <CenteredTextSection
            heading={"Our Sustainability Initiatives"}
            customClass={"mt-0"}
            className="kuda-section mt-0">
            <div className="flex flex-column py-md-4 py-3">
                <div className="flex flex-column">
                    <div className="color-primary text-xbold text-md-s text-center">World Earth Day, April 2024</div>
                    <div className="scam-awareness-text color-black text-center">
                        In April 2024, we partnered with Wecyclers to mark World Earth Day. On the occasion, volunteers from Kuda Microfinance Bank Limited cleaned gutters and collected recyclable waste along Apapa Road, Ebute Metta.
                    </div>
                </div>
            </div>
            <SectionSlider images={wwdImages} />
            <div className="flex flex-column py-md-4 py-3">
                <div className="flex flex-column">
                    <div className="color-primary text-xbold text-md-s text-center">COVID-19 Fund, April 2020</div>
                    <div className="scam-awareness-text color-black text-center">
                        In April 2020, we created a COVID-19 fund and donated ₦500,000 to support food relief efforts led by Lagos Food Bank.
                    </div>
                </div>
            </div>
            <SectionSlider images={covidImages} />


            <div className="flex flex-column py-md-4 py-3">
                <div className="flex flex-column">
                    <div className="color-primary text-xbold text-md-s text-center">International Women’s Day, March 2023</div>
                    <div className="scam-awareness-text color-black text-center">
                        To celebrate the 2023 International Women’s Day, we held an interactive career day featuring Kuda staff and young women from Epe Girls’ Senior High School, Pan Atlantic University, and Africa Agility Foundation at our Lagos head office.
                    </div>
                </div>
            </div>



            <div>


            </div>
        </CenteredTextSection>
    )
}

export default Initiatives;
