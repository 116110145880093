import React from "react";
import TechCrunch from "../../../../../assets/img/techcrunch.svg"
import CNBC from "../../../../../assets/img/cnbc.svg"
import Fintech from "../../../../../assets/img/fintech.svg"
import bbc from "../../../../../assets/img/bbc-new.svg"
import arise from "../../../../../assets/img/arise.jpg"
import Euromoney from "../../../../../assets/img/euromoney.svg";
import WeForum from "../../../../../assets/img/june/we-forum.svg"
import { Fragment } from "react";
import { Img } from 'react-image'

const Publications = () => {
    const publication = [{
        icon: TechCrunch,
        url: "https://techcrunch.com/2021/03/18/kuda-raises-25m-more-led-by-valar-to-become-the-neobank-for-every-african-on-the-planet/"
    },
    {
        icon: WeForum,
        url: "https://www.weforum.org/agenda/2021/06/technology-pioneers-2021-world-economic-forum/"
    },
    {
        icon: Fintech,
        url: "https://www.fintechfutures.com/2021/03/nigerian-challenger-kuda-bank-raises-25m-in-series-a-funding/"
    },
    {
        icon: bbc,
        url: "https://www.bbc.com/news/av/business-55806761/"
    },
    {
        icon: CNBC,
        url: "https://www.cnbc.com/the-worlds-top-250-fintech-companies-2024/"
    },
    {
        icon: Euromoney,
        url: "https://www.euromoney.com/article/b1lhggklb7jbkw/how-fintech-can-save-us-from-covid-19/"

    }]

    return (
        <div className="kuda-publication--wrap" >
            {publication.map((item, i) =>
                <Fragment key={i}>
                    {item.icon === bbc ?
                        <a href={item.url} target="_blank" rel="nofollow noopener noreferrer" key={i} className="publication-image" >
                            <Img src={item.icon} loading="lazy" decode={false} alt={`kuda Microfinance Bank Publications`} style={{ maxHeight: 40 }} />
                        </a> :
                        item.icon === arise ? <a href={item.url} target="_blank" rel="nofollow noopener noreferrer" key={i} className="publication-image" >
                            <Img src={item.icon} loading="lazy" decode={false} alt={`kuda Microfinance Bank Publications`} style={{ maxHeight: 50 }} />
                        </a> :
                            <a href={item.url} target="_blank" rel="nofollow noopener noreferrer" key={i} className="publication-image">
                                <Img src={item.icon} loading="lazy"  alt={`kuda Microfinance Bank Publications`} style={{height:'auto', width:'auto'}}/>
                            </a>}

                </Fragment>)
            }
        </div>
    )
}

export default Publications;
